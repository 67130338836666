import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
import { Switcher, CodeSwitcher } from '../../components/switcher';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Managing Apps and Users`}</h1>
    <p>{`These calls are authenticated using the `}<a parentName="p" {...{
        "href": "/docs/authentication/#admin-token"
      }}>{`Admin Bearer Token`}</a>{` `}</p>
    <h2>{`Listing Applications`}</h2>
    <p>{`To list applications use the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/applications_list"
      }}>{`application`}{`_`}{`list`}</a>{`
call.`}</p>
    <CodeSwitcher languages={{
      bash: `curl -X GET --url https://api-beta.bite.ai/applications/`,
      python: `admin = biteai.Admin(token='[Admin Token]')
apps = admin.applications.list()`
    }} mdxType="CodeSwitcher" />
    <p>{`The response will contain the applications with their IDs. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"count"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"previous"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"results"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"7084efe0-2df3-4a43-a0c4-42e68542915b"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"8f3e07f0b78dcbee9e905162919e8f4986e7766e"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Testing"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2e007f59-6d96-4faf-b817-dcd44427a103"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"c11c85e7884de32226741cecbe029c02e5190d4a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Production"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Creating Users`}</h2>
    <p>{`App users can be created using two methods, client side using an Application Key or server side using an Admin Token. `}</p>
    <h4>{`Client Side Code`}</h4>
    <p>{`If you'd like to register users directly from a mobile or web application you can use the Application Key to do so. This method is ideal if you don't have a backend server for your application.`}</p>
    <p>{`To create a user use one of the `}<a parentName="p" {...{
        "href": "/"
      }}>{`application keys`}</a>{` and provide it as a HTTP Header to the
`}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/applications_users_create"
      }}>{`applications`}{`_`}{`users`}{`_`}{`create`}</a>{`
operation.`}</p>
    <CodeSwitcher languages={{
      bash: `curl -X POST 'https://api-beta.bite.ai/applications/users/' \
     -H 'Authorization: Bearer [APP KEY]'`,
      python: `user = biteai.create_user(
  app_key='[APPLICATION KEY]',
  username='leeroyjenkins'
)`
    }} mdxType="CodeSwitcher" />
    <p>{`The response includes the id of the user and the Bearer token which can used for other operations.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4d09c6a2-99c5-4038-b287-b61720a5dd95"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"username"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"leeroyjenkins"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4>{`Server Side Code`}</h4>
    <p>{`For secure server side code an admin token can be used to create users. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` biteai`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Application`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    app_id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[APP ID]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
    token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[ADMIN TOKEN]"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

user `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'leeroyjenkins'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`Listing Users of an Application`}</h2>
    <p>{`To list the users of an application use the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/applications_users_list"
      }}>{`application`}{`_`}{`users`}{`_`}{`list`}</a>{`
call. `}</p>
    <CodeSwitcher languages={{
      bash: `curl -X GET --url https://api-beta.bite.ai/applications/7084efe0-2df3-4643-a0c4-42e68542915b/users/`,
      python: `app = biteai.Application(
    app_id="[APP ID]", 
    token="[ADMIN TOKEN]"
)
app.users.list()`
    }} mdxType="CodeSwitcher" />
    <p>{`The response will contain the users with their IDs.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"count"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"previous"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"results"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"6aeea928-3cd6-4e73-afb3-9452570b1589"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"username"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"test_account"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"languages"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"en-us"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"meta"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_created"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2018-03-01T19:10:48.510440Z"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Creating a New Token for a User`}</h2>
    <p>{`If a token is lost or has become compromised a new token can be created for a user by calling
`}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/applications_users_tokens_create"
      }}>{`applications`}{`_`}{`users`}{`_`}{`tokens`}{`_`}{`create`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X POST \\
  --url https://api-beta.bite.ai/applications/7084efe0-2df3-4643-a0c4-42e68542915b/users/6feea928-3cd6-4e73-afb3-9452570b1589/tokens/`}</code></pre></div>
    <p>{`The response includes the key.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"9c0709134b5b36372e8a5a292f2d616ac979bdbe"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"created"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-03-07T22:30:48.172184Z"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Revoking a Token for a User`}</h2>
    <p>{`If a token needs to be revoked for a user then call `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/applications_users_tokens_delete"
      }}>{`applications`}{`_`}{`users`}{`_`}{`tokens`}{`_`}{`delete`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X DELETE \\
  --url https://api-beta.bite.ai/applications/7084efe0-2df3-4643-a0c4-42e68542915b/users/6feea928-3cd6-4e73-afb3-9452570b1589/tokens/9c0709134b5b36372e8a5a292f2d616ac979bdbe/ \\`}</code></pre></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      